import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Typography from "@material-ui/core/Typography"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import styled from "styled-components"
import { makeStyles } from "@material-ui/core/styles"
import '../../pages/careers.css'
import '../Jobs/Job.css'


const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  heading: {
    flexBasis: "33.33%",
    flexShrink: 0,
    fontWeight: "500",
    fontSize: "25px"
  },
  secondaryHeading: {
    fontFamily: "Rawline",
    fontSize: "25px"
  },
  column: {
    fontFamily: "Rawline",
    marginLeft: "32%",
    marginRight: "4%",
    fontSize: "25px"
  },
  buttons: {
    justifyContent: "center"
  }
})

const Button = styled.button`
  background-color: #94040b;
  color: #ffffff;
  border: none;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  font-family: "Rawline SemiBold";
  border-radius: 50px;
  font-size: 14px;
  width: 100px;
  margin-left: 5%;
  margin-right: 5%;
  padding-top: 6%;
  padding-bottom: 9%;
  
`


const JobOpenings = () => {

  const data = useStaticQuery(graphql`
      query {
        allContentfulJobOpening {
          edges {
            node {
              orderId
              designation
              city
              date(formatString: "MM/DD/YYYY")
              detailedJobDescription {
                childContentfulRichText {
                  html
                }
              }
              jobDescription {
                childContentfulRichText {
                  html
                }
              }
            }
          }
        }
      }
  `)

  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false)

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <div className="container">
      <div className="col my-5">
        <div className={classes.root + ' jobs-panel'}>
          {
            data.allContentfulJobOpening.edges.map((edge) => {
              return (
                <ExpansionPanel
                  key={edge.node.orderId}
                  expanded={expanded === 'panel' + edge.node.orderId}
                  onChange={handleChange('panel' + edge.node.orderId)}
                  style={{ background: "transparent" }}
                >
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={'panel' + edge.node.orderId + 'bh-content'}
                    id={'panel' + edge.node.orderId + 'bh-header'}
                    style={{ backgroundColor: "none" }}
                  >
                    <div className={classes.heading + ' job-heading'}>
                      <p className="job-title">{edge.node.designation}</p>
                      <p style={{ color: "#94040b" }}>
                        {edge.node.city}
                      </p>
                      <p>{edge.node.date}</p>

                    </div>
                    <Typography className={classes.secondaryHeading + ' job-description job'} dangerouslySetInnerHTML={{ __html: edge.node.jobDescription.childContentfulRichText.html }}>

                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography className={classes.column + ' job-details'} dangerouslySetInnerHTML={{ __html: edge.node.detailedJobDescription.childContentfulRichText.html }}>
                    </Typography>
                  </ExpansionPanelDetails>

                  <ExpansionPanelActions className={classes.buttons}>
                    <a
                      href="mailto:example@123.com"
                      style={{ textDecoration: "none", marginRight: "5%" }}
                    >
                      <Button>
                        Share
                      </Button>
                    </a>
                    <a
                      href="mailto:altaf.qureshi@iaa.com.pk"
                      style={{ textDecoration: "none", marginRight: "5%" }}
                    >
                      <Button>
                        Apply
                      </Button>
                    </a>

                  </ExpansionPanelActions>
                  <hr className="expansion-separator" />
                </ExpansionPanel>

              )
            })
          }
        </div>
      </div>

    </div>

  )
}

export default JobOpenings
