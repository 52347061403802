import React from "react"
import styled from "styled-components"
import Content from "../components/Content"
import Layout from "../components/Layout"
import Heading from '../components/elements/Heading'
import { graphql } from "gatsby"
import './careers.css'
import JobOpenings from "../components/Jobs/JobOpenings"


const Careers = ({ data }) => {

  const ImgDiv = styled.div`
  background-image: url(${data.contentfulBannerImages.image.fluid.src});
  height: 300px;
  color: #ffffff;
  object-fit: cover;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`
  return (
    <Layout>

      <ImgDiv>

          <h3
            style={{
              fontFamily: "Playfair Display",
              fontSize: 50,
              fontWeight: "bold",
              textAlign: "center",
              margin: "auto",
              paddingTop: 100,
            }}
          >
            {data.contentfulBannerImages.heading}
          </h3>
        </ImgDiv>
      <div  className="text-justify" style={{paddingLeft:"5%",paddingRight:"5%"}}>
        <div className="container my-5" style={{textAlign:"-webkit-center"}}>
          <Heading className="headingFix" >
            <Content heading={data.careersBlock.mainHeading1} />
          </Heading>
        </div>
          <div className="mt-md-5 mt-lg-5 mt-xs-3 mt-sm-4 mb-md-5 mb-lg-5 mb-xs-3 mb-sm-4">
            <Content text={data.careersBlock.text.childContentfulRichText.html}/>
          </div>
          <div className="container my-5" style={{textAlign:"-webkit-center"}}>
            <Heading className="headingFix" >
              <Content heading={data.careersBlock.mainHeading2} />
            </Heading>
          </div>
          <JobOpenings/>
          </div>
          
    </Layout>
  )
}
export default Careers


export const query = graphql`
    query CareersQuery {
        contentfulBannerImages(path: {eq: "/Careers"}) {
          image {
            fluid(quality:100) {
              src
            }
          }
          heading
        }
        careersBlock: contentfulPageTextAndHeadings(path: {eq: "/Careers"}) {
        mainHeading1
        mainHeading2
        text {
          childContentfulRichText {
            html
          }
        }
      }
}

`
